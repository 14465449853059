import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/HomeView.vue'),
  },
  {
    path: '/about',
    name: 'about',
    component: () => import(/* webpackChunkName: "about" */ '@/views/AboutView.vue'),
  },
  {
    path: '/cloud-functions',
    name: 'CloudFunctions',
    component: () => import('@/pages/DeployPage.vue'),
  },
  {
    path: '/gdpr',
    name: 'GDPR',
    component: () => import('@/views/GDPR.vue'),
  },
  {
    path: '/roadmap',
    name: 'Roadmap',
    component: () => import('@/views/Roadmap.vue'),
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/Contact.vue'),
  },
  {
    path: '/impressum',
    name: 'Impressum',
    component: () => import('@/views/Impressum.vue'),
  },
  {
    path: '/datenschutz',
    name: 'DataProtection',
    component: () => import('@/views/DataProtection.vue'),
  },
  {
    path: '/login',
    name: 'LoginRegister',
    component: () => import('@/components/LoginRegister.vue'),
  },
  {
    path: '/register',
    name: 'SetupPage',
    component: () => import('@/pages/SetupPage.vue'),
  },
  {
    path: '/documentation',
    name: 'Documentation',
    component: () => import('@/pages/DocumentationPage.vue'),
  },
  {
    path: '/consulting',
    name: 'Consulting',
    component: () => import('@/pages/ConsultingPage.vue'),
  },
  {
    path: '/consulting/business',
    name: 'BusinessConsulting',
    component: () => import('@/pages/BusinessConsultingPage.vue'),
  },
  {
    path: '/adminpage',
    name: 'AdminPage',
    component: () => import('@/views/AdminPage.vue'),
  },
  {
    path: '/setup',
    name: 'SetupPage',
    component: () => import('@/pages/SetupPage.vue'),
  },
  {
    path: '/apps',
    name: 'Apps',
    component: () => import('@/views/Apps.vue'),
  },
  {
    path: '/pricing',
    name: 'Pricing',
    component: () => import('@/components/Pricing.vue'),
  },
  {
    path: '/for-investors',
    name: 'ForInvestors',
    component: () => import('@/pages/ForInvestorsPage.vue'),
  },
  {
    path: '/careers',
    name: 'Careers',
    component: () => import('@/pages/CareersPage.vue'),
  },
  {
    path: '/app/:app_id',
    name: 'ViewSingleAppPage',
    component: () => import('@/views/ViewSingleAppPage.vue'),
  },
];


const router = createRouter({
  history: createWebHistory(),
  routes
});

export default router;
