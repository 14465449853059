import { createStore, Store } from 'vuex';
import axios from 'axios';
const Cookies = require('js-cookie');
const CryptoJS = require('crypto-js');
const { ChaCha20, ChaCha20Key } = require('./chacha20'); // Import the ChaCha20 classes

// Define types
interface App {
  app_id: string;
  app_secret: string;
  company_id: string;
}

interface State {
  login: {
    email: string;
    password: string;
  };
  register: {
    companyName: string;
    email: string;
    password: string;
  };
  accessToken: string | null;
  apps: App[];
  url: string;
  loginError: string | null;
  registerError: string | null;
}

// Function to retrieve state from encrypted cookies
const getInitialState = (): State => {
  Cookies.remove('vuex_state');
  Cookies.remove('auth_token');
  const encryptedStateString = Cookies.get('vuex_state');
  const token = Cookies.get('auth_token'); // Use token for decryption
  if (encryptedStateString && token) {
    try {
      const chachaKey = new ChaCha20Key(token);
      const chacha20 = new ChaCha20(chachaKey);
      const nonce = new Uint32Array(3); // Use a secure nonce
      const decryptedState = chacha20.decrypt(encryptedStateString, nonce);
      return JSON.parse(decryptedState);
    } catch (error) {
      console.error('Failed to decrypt saved state from cookies:', error);
      alert('Failed to decrypt saved state. Resetting to empty state.');
    }
  }

  // Return the default state if no valid saved state exists
  return {
    login: { email: '', password: '' },
    register: { companyName: '', email: '', password: '' },
    accessToken: null,
    apps: [],
    url: 'https://locality.media/api/developer',
    loginError: null,
    registerError: null
  };
};

// Vuex Store
const store: Store<State> = createStore<State>({
  state: getInitialState(),
  mutations: {
    SET_LOGIN_EMAIL(state: State, email: string) {
      state.login.email = email;
    },
    SET_LOGIN_PASSWORD(state: State, password: string) {
      state.login.password = password;
    },
    SET_REGISTER_COMPANY_NAME(state: State, companyName: string) {
      state.register.companyName = companyName;
    },
    SET_REGISTER_EMAIL(state: State, email: string) {
      state.register.email = email;
    },
    SET_REGISTER_PASSWORD(state: State, password: string) {
      state.register.password = password;
    },
    SET_ACCESS_TOKEN(state: State, token: string) {
      state.accessToken = token;
      Cookies.set('auth_token', token, { expires: 30, secure: true });
    },
    SET_APPS(state: State, apps: App[]) {
      state.apps = apps;
    },
    ADD_APP(state: State, app: App) {
      state.apps.push(app);
    },
    CLEAR_LOGIN(state: State) {
      state.login.email = '';
      state.login.password = '';
      state.accessToken = null;
      state.apps = [];
      state.loginError = null;
      state.registerError = null;
      Cookies.remove('auth_token');
      Cookies.remove('vuex_state');
    },
    SET_LOGIN_ERROR(state: State, error: string) {
      state.loginError = error;
    },
    SET_REGISTER_ERROR(state: State, error: string) {
      state.registerError = error;
    }
  },
  getters: {
    isLoggedIn(state: State): boolean {
      return !!state.accessToken;
    }
  },
  actions: {
    async submitRegister({ state, commit, dispatch }) {
      try {
        const hashedPassword = CryptoJS.SHA256(state.register.password + "4f12e1b9e83f76cd37f5cd6a48282b22c0eb2024427841237631ef2f44ae0cd7").toString(CryptoJS.enc.Hex);
        const response = await axios.post(state.url + '/register', {
          company_id: state.register.companyName,
          email: state.register.email,
          password: hashedPassword
        });
        const accessToken = response.data.token;
        commit('SET_ACCESS_TOKEN', accessToken);
        dispatch('listApps');
      } catch (error) {
        let errorMessage = 'Server not reachable';
        if (axios.isAxiosError(error) && error.response && error.response.data) {
          if (error.response.data.reason === 'COMPANY_ALREADY_REGISTERED_WITH_DEVELOPER_ACCOUNT') {
            errorMessage = 'Company already registered with developer account';
          }
        }
        commit('SET_REGISTER_ERROR', errorMessage);
      }
    },
    async submitLogin({ state, commit, dispatch }) {
      try {
        const hashedPassword = CryptoJS.SHA256(state.login.password + "4f12e1b9e83f76cd37f5cd6a48282b22c0eb2024427841237631ef2f44ae0cd7").toString(CryptoJS.enc.Hex);
        const response = await axios.post(state.url + '/login', {
          email: state.login.email,
          password: hashedPassword
        });
        const accessToken = response.data.token;
        commit('SET_ACCESS_TOKEN', accessToken);
        dispatch('listApps');
      } catch (error) {
        let errorMessage = 'Server not reachable';
        if (axios.isAxiosError(error) && error.response && error.response.data) {
          if (error.response.data.reason === 'WRONG_PASSWORD') {
            errorMessage = 'Wrong password';
          }
        }
        commit('SET_LOGIN_ERROR', errorMessage);
      }
    },
    async listApps({ state, commit }) {
      try {
        const response = await axios.post(state.url + '/list_apps', {}, {
          headers: { Authorization: `Bearer ${state.accessToken}` }
        });
        const apps = response.data;
        commit('SET_APPS', apps);
      } catch (error) {
        console.log('List apps error:', error);
      }
    },
    async createApp({ state, commit }, appId: string) {
      try {
        const response = await axios.post(state.url + '/create_app', {
          app_id: appId
        }, {
          headers: { Authorization: `Bearer ${state.accessToken}` }
        });
        const newApp = response.data.app as App;
        commit('ADD_APP', newApp);
      } catch (error) {
        console.log('Create app error:', error);
      }
    },
    logout({ commit }) {
      commit('CLEAR_LOGIN');
    }
  }
});

// Subscribe to mutations to save encrypted state to cookies
store.subscribe((mutation, state: State) => {
  const token = Cookies.get('auth_token');
  if (token) {
    try {
      const chachaKey = new ChaCha20Key(token);
      const chacha20 = new ChaCha20(chachaKey);
      const nonce = new Uint32Array(3); // Use a secure nonce
      const encryptedState = chacha20.encrypt(JSON.stringify(state), nonce);
      Cookies.set('vuex_state', encryptedState, { expires: 30, secure: true });
    } catch (error) {
      console.error('Error encrypting state:', error);
    }
  }
});

export default store;
