<template>
  <div id="app">
    <transition name="fade" mode="out-in" @before-enter="beforeEnter" @enter="enter" @leave="leave">
      <div>
        <!-- Show skeleton or loading spinner -->
        <div v-if="loading" class="loading-spinner"></div>
        <router-view :key="$route.path" />
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loading: true, // initially show loading spinner
    };
  },
  methods: {
    beforeEnter() {
      document.body.style.backgroundColor = '#333';
    },
    enter(el, done) {
      setTimeout(() => {
        document.body.style.backgroundColor = '#222';
        this.loading = false; // hide the spinner once the page transition ends
        done();
      }, 500);
    },
    leave(el, done) {
      document.body.style.backgroundColor = '#fff';
      done();
    }
  }
}
</script>
